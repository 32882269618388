import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import routes from '@/router/routes'
import { findPermission, useAuthStore } from '@/pinia/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to: Route, from: Route, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const { isLoggedIn, getUser, setRedirect } = useAuthStore()
    if (isLoggedIn) {
      next()
      return
    }
    getUser().then(() => {
      next()
    }, () => {
      setRedirect(to.fullPath)
      next({ name: 'login' })
    })
  } else {
    next()
  }
})

router.beforeEach((to: Route, from: Route, next) => {
  const { user } = useAuthStore()
  for (const route of to.matched.filter((route) => route.meta.requiresAnyPermissions || route.meta.requiresAllPermissions)) {
    if (route.meta.requiresAllPermissions &&
      !route.meta.requiresAllPermissions.every((permission: String) => findPermission(permission, user.permissions))
    ) {
      next({ name: 'Dashboard' })
    }

    if (route.meta.requiresAnyPermissions &&
      !route.meta.requiresAnyPermissions.some((permission: String) => findPermission(permission, user.permissions))
    ) {
      next({ name: 'Dashboard' })
    }
    next()
  }
  next()
})

export default router
