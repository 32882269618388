import Vue from 'vue'
import VueI18n, { DateTimeFormats, LocaleMessages } from 'vue-i18n'
import { createI18n } from 'vue-i18n-composable'

Vue.use(VueI18n)

function loadLocaleMessages (): LocaleMessages {
  const locales = require.context('./', true, /[A-Za-z\d-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z\d-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const dateTimeFormats:DateTimeFormats = {
  nl: {
    shortDate: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    },
    shortDateTime: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    shortTime: {
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'nl',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats: dateTimeFormats,
})
