<template>
  <v-app>
    <core-confirm ref="confirm" />
    <core-child />
    <core-snackbar
      v-model="showSnackbar"
      :snackbar="snackbar"
    />
    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      {{ t('pwa.update-is-available') }}
      <v-btn
        text
        @click="refreshApp"
      >
        {{ t('pwa.update') }}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">

  import CoreChild from '@/components/Core/Child.vue'
  import CoreSnackbar from '@/components/Core/Snackbar.vue'
  import CoreConfirm from '@/components/Core/Confirm.vue'
  import { onMounted, provide, ref } from 'vue'
  import { insertSnackbar } from '@/plugins/composables/snackbarHelper'
  import { usePWAUpdate } from '@/plugins/composables/pwaUpdaterHelper'
  import { useI18n } from 'vue-i18n-composable'
  export default {
    name: 'App',
    components: { CoreConfirm, CoreSnackbar, CoreChild },
    setup () {
      const confirm = ref(null)
      const { show, snackbar } = insertSnackbar()
      const { updateExists, refreshApp } = usePWAUpdate()
      const { t } = useI18n()

      onMounted(() => {
        provide('confirm-dialog', confirm.value.doConfirm)
      })

      return {
        confirm,
        showSnackbar: show,
        snackbar,
        updateExists,
        refreshApp,
        t,
      }
    },
    mounted () {
      // this.$root.$snackbar = this.$refs.snackbar.showSnackbar
      this.$root.$confirm = this.$refs.confirm.doConfirm
    },
  }
</script>
