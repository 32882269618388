import axios, { AxiosResponse, HttpStatusCode, InternalAxiosRequestConfig } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import router from '@/router'
import { DateTime } from 'luxon'
import { useAuthStore } from '@/pinia/auth'
import { externalResultsType } from '@/helpers/validation'
import retry from 'axios-retry-after'
import { Meta } from '@/api/types/meta'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
  withXSRFToken: true,
})

const authInterceptor = (config: InternalAxiosRequestConfig) => {
  if (config.headers['Content-Type'] === 'multipart/form-data') {
    return config
  }

  if (config.params) {
    config.params = decamelizeKeys(config.params)
  }

  if (config.data) {
    config.data = decamelizeKeys(config.data)
  }

  return config
}

apiClient.interceptors.request.use(authInterceptor)

const isoDateFormat = /^\d{4}-\d{2}-\d{2}Z\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

function isIsoDateString (value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

export function handleDates (body: any) {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body
  }

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value)) body[key] = DateTime.fromISO(value)
    else if (typeof value === 'object') handleDates(value)
  }
}

export class ValidationError {
  public errors: Map<String, String[]>;

  public parseDotNotation (str, val, obj) {
    let currentObj = obj
    const keys = str.split('.')
    let i
    const l = keys.length - 1

    if (l > 0) {
      let key

      for (i = 0; i < l; ++i) {
        key = keys[i]
        currentObj[key] = currentObj[key] || {}
        currentObj = currentObj[key]
      }

      currentObj[keys[i]] = val
      delete obj[str]
    }
  }

  public toExternalResults (): Object {
    const fromEntries = Object.fromEntries(this.errors)
    for (const key in fromEntries) {
      this.parseDotNotation(key, fromEntries[key], fromEntries)
    }

    return camelizeKeys(fromEntries)

    // return camelizeKeys(Object.fromEntries(this.errors))
  }

  public toExternalResultsKeyed = function (key: string): Object {
    const self = this
    const obj = {}
    obj[key] = self.toExternalResults()
    return obj
  }

  constructor (errors: Object) {
    this.errors = new Map(Object.entries(errors))
  }
}

// Response interceptor
apiClient.interceptors.response.use(null, retry(apiClient, {
  wait (error) {
    return new Promise(
      // Use X-Retry-After rather than Retry-After
      resolve => setTimeout(resolve, error.response.headers['retry-after'] * 1000),
    )
  },
}))

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && response.headers['content-type'] === 'application/json') {
      response.data = camelizeKeys(response.data)
      // handleDates(response.data)
    }

    return response
  },
  (error) => {
    const { status, data } = error.response
    if (status === HttpStatusCode.TooManyRequests) {
      return
    }

    if (process.env.NODE_ENV !== 'production' && status !== HttpStatusCode.UnprocessableEntity) {
      console.log(error)
    }
    const { setRedirect, authenticating } = useAuthStore()

    if (status >= HttpStatusCode.InternalServerError) {
    }

    if (status === HttpStatusCode.UnprocessableEntity) {
      if (data.errors !== undefined) {
        return Promise.reject(new ValidationError(camelizeKeys(data.errors)))
      }
    }

    if (status === HttpStatusCode.Unauthorized || status === 419) {
      if (!authenticating) {
        setRedirect(router.currentRoute.fullPath)
        return Promise.resolve(router.push({ name: 'login' }))
      }
    }
    return Promise.reject(error)
  })

interface withExternalResultsOptions {
  keyed?: string,
  prefix?: string
}

function withExternalResults<T = any, R = AxiosResponse<T>> (axiosFunction: Promise<R>, externalErrors: externalResultsType, options?: withExternalResultsOptions) {
  return axiosFunction.then((response) => {
    return Promise.resolve(response)
  }).catch(error => {
    if (error instanceof ValidationError) {
      if (options.keyed) {
        externalErrors.value = error.toExternalResultsKeyed(options.keyed)
      } else {
        externalErrors.value = error.toExternalResults()
      }
    }
    return Promise.reject(error)
  })
}

interface MetaResponse {
  meta: Meta
}

interface DataResponse<T = any> {
  data: T
}

interface DataArrayResponse<T = any> {
  data: T[]
}

export default apiClient
export {
  withExternalResults, DataResponse, DataArrayResponse, MetaResponse,
}
