<template>
  <v-fade-transition
    mode="out-in"
  >
    <slot>
      <router-view
        v-bind="$attrs"
        v-on="$listeners"
      />
    </slot>
  </v-fade-transition>
</template>

<script lang="ts">
  export default {
    name: 'CoreChild',
  }
</script>

<style scoped>

</style>
