import { ref } from 'vue'

export function usePWAUpdate () {
  const refreshing = ref<Boolean>(false)
  const updateExists = ref<Boolean>(false)
  const registration = ref(null)

  const showRefreshUI = (e) => {
    registration.value = e.detail
    updateExists.value = true
  }

  const refreshApp = () => {
    updateExists.value = false
    if (!registration.value || !registration.value.waiting) return

    registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  document.addEventListener('swUpdateFound', showRefreshUI, { once: true })

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing.value) return

    refreshing.value = true
    window.location.reload()
  })

  return {
    updateExists,
    refreshApp,
  }
}
