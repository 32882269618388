import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import nl from 'vuetify/src/locale/nl'

Vue.use(Vuetify)

// https://huemint.com/bootstrap-basic/#palette=f4f6db-ffffff-242325-0f5646-259d5a-b5cf03
export default new Vuetify({
  lang: {
locales: { nl },
  current: 'nl',
},
    theme: {
        themes: {
            light:
                {
                    primary: '#0f5646',
                    secondary: '#259d5a',
                    accent: '#ffeb3b',
                    error: '#f44336',
                    warning: '#f6c901',
                    info: '#b5cf03',
                    success: '#01b53d',
                },
        },
    },
})
