<template>
  <v-snackbar
    v-model="show"
    color="snackbar.color"
    outlined
    :timeout="snackbar.timeout"
  >
    {{ snackbar.text }}
    <template
      v-if="snackbar.action"
      #action="{ attrs }"
    >
      <v-btn
        icon
        v-bind="attrs"
        @click="show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
  import { computed } from 'vue'
  import ISnackbar from '@/types/Snackbar'

  export default {
    name: 'CoreSnackbar',
    props: {
      value: { type: Boolean, required: true },
      snackbar: { type: Object as () => ISnackbar, required: true },
    },
    setup (_, { emit }) {
      const show = computed({
        get: () => _.value,
        set: (val) => { emit('input', val) },
      })

      return {
        show,
      }
    },

  }
</script>

<style scoped>

</style>
