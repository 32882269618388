<template>
  <v-dialog
    v-model="dialog"
    :max-width="confirmOptions.width"
    :style="{ zIndex: confirmOptions.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar
        dark
        :color="confirmOptions.color"
        dense
        flat
      >
        <v-toolbar-title class="white--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4"
      >
        {{ message }}
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn
          color="primary darken-1"
          text
          @click.native="agree"
        >
          {{ t('form.options.yes') }}
        </v-btn>
        <v-btn
          color="grey"
          text
          @click.native="cancel"
        >
          {{ t('actions.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { ref } from 'vue'
  import { IConfirmOptions, IDialog } from '@/plugins/composables/confirmDialogHelper'
  import { useI18n } from 'vue-i18n-composable'

  export default {
    name: 'CoreConfirm',
    setup () {
      const { t } = useI18n()
      const dialog = ref<Boolean>(false)
      const resolveFunction = ref<Function>(null)

      const rejectFunction = ref<Function>(null)
      const message = ref<string>(null)
      const title = ref<string>(null)

      const confirmOptions = ref<IConfirmOptions>({
        color: 'primary',
        width: 290,
        zIndex: 200,
      })

      const doConfirm = function (data: IDialog) {
        dialog.value = true
        title.value = data.title
        message.value = data.message

        confirmOptions.value = Object.assign(confirmOptions.value, data.options)
        return new Promise((resolve, reject) => {
          resolveFunction.value = resolve
          rejectFunction.value = reject
        })
      }

      const agree = () => {
        resolveFunction.value(true)
        dialog.value = false
      }

      const cancel = () => {
        resolveFunction.value(false)
        dialog.value = false
      }

      return {
        t,
        dialog,
        resolve: resolveFunction,
        reject: rejectFunction,
        message,
        title,
        confirmOptions,
        doConfirm,
        agree,
        cancel,
      }
    },

  }
</script>

<style scoped>

</style>
