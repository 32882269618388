import i18n from '@/i18n'
import Vue from 'vue'

import Vue2Filters from 'vue2-filters'
import { DateTime } from 'luxon'

const Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false,
  },
  number: {
    format: '0',
    thousandsSeparator: '.',
    decimalSeparator: ',',
  },
  bytes: {
    decimalDigits: 2,
  },
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: ',',
  },
  currency: {
    symbol: '€',
    decimalDigits: 0,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false,
  },
  pluralize: {
    includeNumber: false,
  },
  ordinal: {
    includeNumber: false,
  },
}

Vue.use(Vue2Filters, Vue2FiltersConfig)

const formatDate = (value) => (value !== null && value !== '') ? i18n.d(Date.parse(value), 'shortDate') : null

Vue.filter('formatDate', (value) => {
  if (value) {
    return formatDate(value)
  } else {}
})

Vue.filter('age', (value, death) => {
  if (value) {
    if (death) {
      console.log(death)
      return DateTime.fromISO(death).diff(DateTime.fromISO(value)).toFormat('y')
    }

    return DateTime.now().diff(DateTime.fromISO(value))
      .toFormat('y')
  }
})

Vue.filter('formatDateTime', (value) => {
  if (value) {
    return i18n.d(Date.parse(value), 'shortDateTime')
  }
})

Vue.filter('formatTime', (value) => {
  if (value) {
    return i18n.d(Date.parse(value), 'shortTime')
  }
})

export {
  formatDate,
}
