import { AdvicePersonInfo } from '@/api/types/advice'
import { MaritalState, MaritalStateCondition } from '@/api/types/marital'

enum EnumRelation {
  child = 'child',
  requester = 'requester',
  parent = 'parent',
  partner = 'partner',
  grandchild = 'grandchild',
  sibling = 'sibling',
}

interface PersonInfo {
  id: number,
  firstName?: string | null,
  relation?: string | null,
  currentRelationshipId?: number | null
}

interface ParentInfo {
  text: string,
  ids: number[]
}

interface RelationshipInfo {
  id: number,
  people?: PersonInfo[] | null,
  children?: PersonInfo[] | null,
  maritalStateId?: number | null,
  maritalState?: MaritalState | null,
  maritalStateConditionId?: number | null,
  maritalStateCondition?: MaritalStateCondition | null
}

interface Person extends AdvicePersonInfo {
  sex?: string | null,
  dateOfBirth?: Date | null,
  dateOfDeath?: Date | null,
  maritalStateId?: number | null,
  maritalStateConditionId?: number | null,
  dateOfDivorce?: Date | null,
  dateOfWidow?: Date | null,
  deceased?: Boolean | null,
  disabled?: Boolean | null,
  disowned?: Boolean | null,
  recognized?: Boolean | null,
  recognizedWished?: Boolean | null,
  disownedWished?: Boolean | null,
  recognizedRemark?: string | null,
  halfSibling?: Boolean | null,
  substitutionOrAccretion?: Boolean | null,
  includingDescendents?: Boolean | null,
  relation?: string | null,
}

interface AddPerson extends Person {
  parents?: number[] | null
  relationshipId?: number | null
  parentFor?: number | null
  partnerFor?: number | null
}

export {
  Person, PersonInfo, RelationshipInfo, EnumRelation, ParentInfo, AddPerson,
}
