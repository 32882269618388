import apiClient from '@/api/apiClient'
import { User } from '@/api/types/user'
import LoginForm from '@/types/loginForm'
import { Profile } from '@/api/types/profile'
import { PasswordRequest } from '@/api/types/auth'

async function apiAuthLogin (request: LoginForm) {
  await apiClient.get('/sanctum/csrf-cookie')
  return apiClient.post('login', request)
}

async function apiForgotPassword (request) {
  await apiClient.get('/sanctum/csrf-cookie')
  return apiClient.post('forgot-password', request)
}

async function apiResetPassword (request) {
  await apiClient.get('/sanctum/csrf-cookie')
  return apiClient.post('reset-password', request)
}

const apiAuthEnableTwoFactor = async function (password: string) {
  await apiClient.post('user/confirm-password', { password: password })
  return apiClient.post('/user/two-factor-authentication')
}

const apiAuthConfirmTwoFactor = (otp: string) => apiClient.post('/user/confirmed-two-factor-authentication', { code: otp })

const apiAuthTwoFactorQRCode = () => apiClient.get('/user/two-factor-qr-code')

const apiAuthDisableTwoFactor = async function (password: string) {
  await apiClient.post('user/confirm-password', { password: password })
  return apiClient.delete('/user/two-factor-authentication')
}

const apiGenerateTwoFactorRecoveryCodes = async function (password: string) {
  await apiClient.post('user/confirm-password', { password: password })
  return apiClient.post('/user/two-factor-recovery-codes')
}

const apiGetTwoFactorRecoveryCodes = () => {
  return apiClient.get('/user/two-factor-recovery-codes')
}

const apiAuthLoginTwoFactor = (otp: string) => apiClient.post('/two-factor-challenge', { code: otp })

const apiAuthLogout = () => apiClient.post('/logout')

const apiAuthMe = () => apiClient.get<User>('/api/user')
const apiAuthMePutProfile = (profile: Profile) => apiClient.put<User>('/api/user', profile)
const apiAuthMePutPassword = (request: PasswordRequest) => apiClient.put<User>('/user/password', request)
const apiAuthMePutAvatar = (data, config) => apiClient.put<User>('/api/user/avatar', data, config)

export {
  apiAuthLogin,
  apiAuthLoginTwoFactor,
  apiAuthMePutPassword,
  apiAuthLogout,
  apiForgotPassword,
  apiResetPassword,
  apiGenerateTwoFactorRecoveryCodes,
  apiGetTwoFactorRecoveryCodes,
  apiAuthMe,
  apiAuthMePutAvatar,
  apiAuthMePutProfile,
  apiAuthEnableTwoFactor,
  apiAuthDisableTwoFactor,
  apiAuthTwoFactorQRCode,
  apiAuthConfirmTwoFactor,
}
