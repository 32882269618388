import PortalVue from 'portal-vue'
import Vue, { markRaw } from 'vue'

import './filters'
import router from '@/router'
import pinia from '@/pinia'

Vue.use(PortalVue)

pinia.use(({ store }) => {
  store.router = markRaw(router)
})
