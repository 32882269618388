import { inject, provide, ref } from 'vue'

import ISnackbar from '@/types/Snackbar'

export type ShowSnackBarFunction = {
  (show: ISnackbar): void
}

export function useSnackbar () {
  const {
    showSnackbar,
    infoSnackbar,
  }: { showSnackbar: ShowSnackBarFunction, infoSnackbar: Function } = inject('snackBar')

  return {
    showSnackbar, infoSnackbar,
  }
}

export function insertSnackbar () {
  const show = ref<Boolean>(false)
  const snackbar = ref<ISnackbar>({
    actionText: null,
    action: true,
    color: 'success',
    emoji: null,
    slug: null,
    text: null,
    timeout: 2000,
  })

  function infoSnackbar ({
                           text,
                         }) {
    showSnackbar({ action: false, actionText: null, color: 'info', emoji: null, slug: null, timeout: 2000, text })
  }

  function showSnackbar (showSnackbar: ISnackbar,
  ) {
    const standard: ISnackbar = {
      text: '',
      action: true,
      color: 'success',
      timeout: 2000,
      actionText: null,
      slug: null,
      emoji: null,
    }

    Object.assign(standard, showSnackbar)

    Object.assign(snackbar.value, standard)
    //
    // snackbar.value.actionText = actionText
    // snackbar.value.action = action
    // snackbar.value.color = color
    // snackbar.value.emoji = emoji
    // snackbar.value.slug = slug
    // snackbar.value.text = text
    // snackbar.value.timeout = timeout
    show.value = true
  }

  provide('snackBar', {
    snackbar, showSnackbar, infoSnackbar,
  })

  return {
    show,
    snackbar,
    infoSnackbar,
  }
}
